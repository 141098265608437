class LambdaModalSize
{

    static Small        = new LambdaModalSize("Small", "modal-sm");
    static Default      = new LambdaModalSize("Default", null);
    static Large        = new LambdaModalSize("Large", "modal-lg");
    static ExtraLarge   = new LambdaModalSize("Extra Large", "modal-xl");

    Name        = null;
    ClassName   = null;

    constructor(name, classname)
    {
        this.Name       = name;
        this.ClassName  = classname;
    }

}

class LambdaFullscreenCondition
{

    static SmallDown    = new LambdaFullscreenCondition("modal-fullscreen-sm-down");
    static MediumDown   = new LambdaFullscreenCondition("modal-fullscreen-md-down");
    static LargeDown    = new LambdaFullscreenCondition("modal-fullscreen-lg-down");
    static XLDown       = new LambdaFullscreenCondition("modal-fullscreen-xl-down");
    static XXLDown      = new LambdaFullscreenCondition("modal-fullscreen-xxl-down");

    ClassName = null;

    constructor(classname)
    {
        this.ClassName = classname;
    }
}

var LAMBDA_MODAL_LAST_ID = 0;

class LambdaModal
{

    ID                      = 0;
    IDName                  = null;
    BootstrapHandle         = null;
    HTMLElem                = null;
    StaticBackdrop          = false;
    Scrollable              = false;
    Center                  = false;
    Fullscreen              = false;
    FullscreenCondition     = null;
    Size                    = LambdaModalSize.Default;
    ContentTitle            = "Modal Title";
    ContentBody             = "My awesome modal!";
    CloseButton             = true;
    Classes                 = [];
    Buttons                 = [];
    Rendered                = false;
    EventClosed             = function() {};
    EventFuncAsyncSuccess   = function() {};
    EventFuncAsyncFailed    = function() {};
    EventFuncAsyncDone      = function() {};
    EventInternalClose      = function() {};
    DeleteOnHide            = true;
    

    setStaticBackdrop(bStaticBG)
    {
        this.StaticBackdrop = bStaticBG;
    }

    setScrollable(bScroll)
    {
        this.Scrollable = bScroll;
    }

    setCenter(bCenter)
    {
        this.Center = bCenter;
    }

    setFullscreen(bFullScr)
    {
        this.Fullscreen = bFullScr;
    }

    setFullscreenCondition(cFullScrCond)
    {
        this.FullscreenCondition = bFullScrCond;
    }

    setSize(cSize)
    {
        this.Size = cSize;
    }

    setCloseButton(bClose)
    {
        this.CloseButton = bClose;

        if(this.Rendered)
        {
            if(bClose)
                $(`#${this.IDName}_btn_close`).show();
            else
                $(`#${this.IDName}_btn_close`).hide();
        }
    }

    setTitle(sTitle)
    {
        this.ContentTitle = sTitle;

        if(this.Rendered)
        {
            $(`#${this.IDName}_title`).html(sTitle);
        }

    }

    setBody(sBody)
    {
        this.ContentBody = sBody;

        if(this.Rendered)
        {
            $(`#${this.IDName}_body`).html(sBody);
        }
    }

    addButton(cBtn)
    {
        if(this.Rendered)
        {
            $(`#${this.IDName}_footer`).append(cBtn.render());
            cBtn.start();
        }
        else
        {
            this.Buttons.push(cBtn);
        }
    }

    addCloseButton(onClosedFunc)
    {
        var self = this;

        if(onClosedFunc == null)
            onClosedFunc = function() {};

        this.EventInternalClose = () => {

            onClosedFunc();

        }

        this.addButton(new LambdaButton().setClass("btn-danger").setButtonType(LambdaButtonType.FormButton).setText("Close").setIcon('fa-solid fa-xmark').eventClick(function() {

            self.hide();

        }));
    }

    addSuccessButton(text, onClick, icon = 'fa-solid fa-check')
    {
        var self = this;

        if(onClick == null)
            onClick = function() {};

        this.addButton(new LambdaButton().setClass("btn-success").setButtonType(LambdaButtonType.FormButton).setText(text).setIcon(icon).eventClick(function() {

            onClick();

        }));
    }

    clearFooter()
    {
        $(`#${this.IDName}_footer`).html("");
    }

    disableButtons()
    {
        $(`#${this.IDName}_footer`).children().each(function() { 

            if($(this).prev().is("button"))
            {
                $(this).attr('disabled', "");
            }
            else
            {
                $(this).addClass("disabled");
            }

        });
    }

    enableButtons()
    {
        $(`#${this.IDName}_footer`).children().each(function() { 

            if($(this).prev().is("button"))
            {
                $(this).removeAttr('disabled');
            }
            else
            {
                $(this).removeClass("disabled");
            }

        });
    }

    setLoading()
    {
        this.setBody(`
        <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        `);

        this.setTitle(`<i class="fa-solid fa-hourglass"></i> Loading, please wait.`);
        this.clearFooter();
        this.setCloseButton(false);

    }

    asyncGET(endpoint, useRoute = true, bRefresh = false)
    {

        this.setLoading();

        var req = LambdaHTTP.Get(endpoint, useRoute);

        req.OnSuccess(data => {

            var result = this.EventFuncAsyncSuccess(data);

            if(result == true)
                return;

            this.setTitle(`<i class="fa-solid fa-check"></i> All Done`);
            this.setBody(`
            <div class="alert alert-success" role="alert">${data.Message}</div>
            `);

        });

        req.OnFail(data => {

            var result = this.EventFuncAsyncFailed(data);

            if(result == true)
                return;

            this.setTitle(`<i class="fa-solid fa-triangle-exclamation"></i> Error`);
            this.setBody(`
            <div class="alert alert-danger" role="alert">${data.Message}</div>
            `);

        });

        req.OnCompleted(() => {

            var result = this.EventFuncAsyncDone();

            if(result == true)
                return;

            this.setCloseButton(true);
            this.addCloseButton();
            this.EventInternalClose = () => {

                if(bRefresh)
                    location.reload();

            };


        });

        req.Send();
    }

    asyncPOST(endpoint, formData = {}, useRoute = true, bRefresh = false, bFormData = false)
    {

        var self = this;

        this.setLoading();

        var req = LambdaHTTP.Post(endpoint, useRoute);

        if(bFormData == false)
        {
            Object.keys(formData).forEach(key => {

                var val = formData[key];

                req.AddFormValue(key, val);

            });
        }
        else
        {
            //console.log("Using form data:");
            //console.log(formData);
            req.SetData(formData);
        }

        req.OnSuccess(data => {

            var result = this.EventFuncAsyncSuccess(data);

            if(result == true)
                return;

            this.setTitle(`<i class="fa-solid fa-check"></i> All Done`);
            this.setBody(`
            <div class="alert alert-success" role="alert">${data.Message}</div>
            `);

        });

        req.OnFail(data => {

            var result = this.EventFuncAsyncFailed(data);

            if(result == true)
                return;

            this.setTitle(`<i class="fa-solid fa-triangle-exclamation"></i> Error`);
            this.setBody(`
            <div class="alert alert-danger" role="alert">${data.Message}</div>
            `);


        });

        req.OnCompleted(() => {

            var result = this.EventFuncAsyncDone();

            if(result == true)
                return;

            this.setCloseButton(true);
            this.addCloseButton();
            this.EventInternalClose = () => {

                if(bRefresh)
                    location.reload();

            };

        });

        req.Send();

    }

    eventOnClosed(func)
    {
        this.EventClosed = func;
    }

    eventAsyncSuccess(func)
    {
        this.EventFuncAsyncSuccess = func;
    }

    eventAsyncFailed(func)
    {
        this.EventFuncAsyncFailed = func;
    }

    eventAsyncCompleted(func)
    {
        this.EventFuncAsyncDone = func;
    }

    render()
    {
        LAMBDA_MODAL_LAST_ID = LAMBDA_MODAL_LAST_ID + 1;

        var self        = this;

        this.ID         = LAMBDA_MODAL_LAST_ID;
        this.IDName     = `modal_gen_${this.ID}`;

        var closeBtn = ``;

        if(this.CloseButton)
        {
            closeBtn = `<button type="button" id="${this.IDName}_btn_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>`;
        }

        var classList   = [];
        var attribs     = [];

        if(this.StaticBackdrop == true)
        {
            attribs.push('data-bs-backdrop="static" data-bs-keyboard="false"');
        }

        if(this.Size.ClassName != null)
            classList.push(this.Size.ClassName);

        if(this.Scrollable)
        {
            classList.push('modal-dialog-scrollable');
        }

        if(this.Center)
        {
            classList.push('modal-dialog-centered');
        }

        if(this.Fullscreen)
        {
            if(this.FullscreenCondition == null)
            {
                classList.push('modal-fullscreen');
            }
            else
            {
                classList.push(this.FullscreenCondition.ClassName);
            }
        }

        var footer = [];

        this.Buttons.forEach(elem => footer.push(elem.render()));

        $("body").append(`
            <div class="modal fade" id="${this.IDName}" ${attribs.join(' ')} tabindex="-1" aria-labelledby="${this.IDName}_title" aria-hidden="true">
                <div class="modal-dialog ${classList.join(' ')}">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="${this.IDName}_title">${this.ContentTitle}</h5>
                            ${closeBtn}
                        </div>
                        <div class="modal-body" id="${this.IDName}_body">
                            ${this.ContentBody}
                        </div>
                        <div class="modal-footer" id="${this.IDName}_footer">
                            ${ footer.join(' ') }
                        </div>
                    </div>
                </div>
            </div>
        `);

        this.Rendered = true;

        var modalDomElem = document.getElementById(this.IDName);

        this.HTMLElem           = modalDomElem;
        this.BootstrapHandle    = bootstrap.Modal.getOrCreateInstance(modalDomElem);

        modalDomElem.addEventListener('hidden.bs.modal', event => {

            self.EventClosed();
            self.EventInternalClose();
            self.delete();

        });

        this.Buttons.forEach(elem => elem.start());

    }

    show()
    {
        this.BootstrapHandle.show();
    }

    hide(bDelete = true)
    {

        this.DeleteOnHide = bDelete;

        this.BootstrapHandle.hide();
    }

    delete()
    {

        if(this.DeleteOnHide == false)
        {
            Log.Info(`Modal #${this.ID} not deleting..`);
            return;
        }

        this.BootstrapHandle.dispose();

        var elem = document.getElementById(this.IDName);
        elem.remove();

        this.BootstrapHandle = null;

    }

    showError(err)
    {
        this.setTitle(`<i class="fa-solid fa-triangle-exclamation"></i> Error`);
        this.setBody(`
        <div class="alert alert-danger" role="alert">${err}</div>
        `);
    }

    showSuccess(msg)
    {
        this.setTitle(`<i class="fa-solid fa-check"></i> All Done`);
        this.setBody(`
        <div class="alert alert-success" role="alert">${msg}</div>
        `);
    }

    setBodyURL(url, onCompleted)
    {
        var self = this;

        $.get(url, data => {
            self.setBody(data);
            onCompleted(data);
        });
    }

    static confirm(title, text, onYes = function() {}, onNo = function() {}, titleIcon = 'fa-solid fa-circle-question', yesText = "Yes", noText="No", yesIcon="fa-solid fa-check", noIcon = "fa-solid fa-xmark")
    {
        var modal = new LambdaModal();
        modal.setSize(LambdaModalSize.Large);
        modal.setStaticBackdrop(true);
        modal.setTitle(`<i class="${ titleIcon }"></i> ${title}`);
        modal.setBody(`${text}`);
        modal.addButton(new LambdaButton().setClass("btn-success").setButtonType(LambdaButtonType.FormButton).setText(yesText).setIcon(yesIcon).eventClick(function() {

            onYes();

        }));
        modal.addButton(new LambdaButton().setClass("btn-danger").setButtonType(LambdaButtonType.FormButton).setText(noText).setIcon(noIcon).eventClick(function() {

            onNo();

        }));
        modal.render();
        modal.show();

        return modal;

    }

    static errorModal(text, title = "Error", icon = 'fa-solid fa-triangle-exclamation', onClose = function() {})
    {
        var modal = new LambdaModal();
        modal.setSize(LambdaModalSize.Large);
        modal.setStaticBackdrop(true);
        modal.showError(text);
        modal.setTitle(`<i class="${icon}"></i> ${title}`);
        modal.addCloseButton(onClose);
        modal.render();
        modal.show();
    }

}

let LAMBDA_BTN_LAST_ID = 0;

class LambdaButtonType
{
    static Link         = new LambdaButtonType();
    static FormButton   = new LambdaButtonType();
}

class LambdaButton
{
    ID          = 0;
    IDName      = null;
    ClassName   = "btn-primary";
    HasIcon     = false;
    Icon        = null;
    Text        = "Button";
    ButtonType  = LambdaButtonType.Link;
    LinkURL     = "#";
    LinkTarget  = "_self";
    OnClick     = function() {};

    setClass(sClass)
    {
        this.ClassName = sClass;

        return this;
    }

    setIcon(sIcon)
    {
        this.HasIcon    = true;
        this.Icon       = sIcon;

        return this;
    }

    setText(sText)
    {
        this.Text = sText;

        return this;
    }

    setButtonType(cType)
    {
        this.ButtonType = cType;

        return this;
    }

    setLinkURL(url)
    {
        this.LinkURL = url;

        return this;
    }

    setLinkTarget(target)
    {
        this.LinkTarget = target;

        return this;
    }

    eventClick(func)
    {
        this.OnClick = func;

        return this;
    }

    render()
    {
        LAMBDA_BTN_LAST_ID = LAMBDA_BTN_LAST_ID + 1;

        this.ID     = LAMBDA_BTN_LAST_ID;
        this.IDName = `btn_gen_${this.ID}`;

        if(this.ButtonType == LambdaButtonType.Link)
        {
            if(this.HasIcon)
            {
                return `<a href="${this.LinkURL}" target="${this.LinkTarget}" class="btn ${this.ClassName}" id="${this.IDName}"><i class="${this.Icon}"></i> ${this.Text}</a>`;
            }

            return `<a href="${this.LinkURL}" target="${this.LinkTarget}" class="btn ${this.ClassName}" id="${this.IDName}">${this.Text}</a>`;
        }
        else
        {
            if(this.HasIcon)
            {
                return `<button type="button" id="${this.IDName}" class="btn ${this.ClassName}"><i class="${this.Icon}"></i> ${this.Text}</button>`;
            }

            return `<button type="button" id="${this.IDName}" class="btn ${this.ClassName}">${this.Text}</button>`;
        }
    }

    start()
    {

        var self = this;

        $(`#${this.IDName}`).click(function(event) {

            if(this.ButtonType == LambdaButtonType.Link && this.LinkURL == "#")
            {
                event.preventDefault();
            }

            self.OnClick();

        });
    }

}

window.LambdaModalSize = LambdaModalSize;
window.LambdaFullscreenCondition = LambdaFullscreenCondition;
window.LambdaModal = LambdaModal;
window.LambdaButtonType = LambdaButtonType;
window.LambdaButton = LambdaButton;