class HTTPMethod
{

    static GET  = new HTTPMethod('GET');
    static POST = new HTTPMethod('POST');

    XMLType = 'GET';

    constructor(xmlType)
    {
        this.XMLType = xmlType;
    }
}

class HTTPContent
{

    static Plain            = new HTTPContent("Plain Text", "text/plain");
    static JSON             = new HTTPContent("Javascript Object Notation", "application/json");
    static HTML             = new HTTPContent("HTML", "text/html");
    static Form             = new HTTPContent("Form", "application/x-www-form-urlencoded");
    static MultipartForm    = new HTTPContent("Multipart Form", "multipart/form-data");

    Name = "Binary";
    Type = "application/octet-stream";

    constructor(name, type)
    {
        this.Name = name;
        this.Type = type;
    }
}

class LambdaHTTP
{
    Method              = HTTPMethod.GET;
    URL                 = "/get";
    UseRoute            = true;
    ContentType         = HTTPContent.Form;
    OverrideContentType = false;
    Data                = null;
    EventSuccess        = function() {};
    EventFailed         = function() {};
    EventDone           = function() {};
    EventProgress       = function() {};
    HasFormData         = false;
    FormDataObj         = null;
    SuccessCodes        = [200];

    constructor(method, url, useRoute = true)
    {
        this.Method         = method,

        this.CalculateURL(url, useRoute);

    }

    CalculateURL(url, useRoute)
    {
        if(useRoute)
        {

            if(url.startsWith("/"))
            {
                url = url.substr(1);
            }

            if(url.startsWith("http"))
            {
                this.URL        = url;
                this.UseRoute   = true;
                return this;
            }

            this.URL = `${LambdaHTTP.GetBaseURL()}/${url}`;
        }
        else
        {
            this.URL = url;
        }

        this.UseRoute = useRoute;

        return this;

    }

    SetData(data)
    {
        this.Data = data;

        return this;
    }

    SetContentType(type)
    {
        this.ContentType = type;
        this.OverrideContentType = true;

        return this;
    }

    SetURL(url, useRoute = true)
    {

        this.CalculateURL(url, useRoute);

        return this;

    }

    AddFormValues(arr)
    {
        var self = this;

        Object.keys(arr).forEach(key => {

            var val = arr[key];

            self.AddFormValue(key, val);

        });

        return this;

    }

    AddFormValue(key, val)
    {
        if(this.FormDataObj == null)
        {
            this.FormDataObj = new FormData();
            this.HasFormData = true;
        }

        this.FormDataObj.append(key, val);

        return this;

    }

    addSuccessCode(code)
    {
        this.SuccessCodes.push(code);

        return this;

    }

    OnSuccess(func)
    {
        this.EventSuccess = func;

        return this;
    }

    OnFail(func)
    {
        this.EventFailed = func;

        return this;
    }

    OnCompleted(func)
    {
        this.EventDone = func;

        return this;
    }
    
    OnProgress(func)
    {
        this.EventProgress = func;

        return this;
    }

    static IsValidJSON(str)
    {
        try
        {
            JSON.parse(str);
        }
        catch(e) {
            return false;
        }

        return true;
    }

    Send()
    {
        var hndl = new XMLHttpRequest();
        hndl.open(this.Method.XMLType, this.URL, true);
        
        if(this.UseRoute)
        {
            hndl.setRequestHeader("X-CSRF-TOKEN", LambdaHTTP.CSRFToken());
        }

        if(this.OverrideContentType)
            hndl.setRequestHeader('Content-Type', `${this.ContentType.Type}`);

        hndl.addEventListener("load", event => {

            if(!LambdaHTTP.IsValidJSON(event.target.response))
            {
                this.EventDone();
                this.EventFailed({
                    "Code": -1,
                    "Message": "There is an API issue, the expected format was not given."
                });

                return;
            }

            var json = JSON.parse(event.target.response);

            if(json == null)
            {

                this.EventDone();

                this.EventFailed({
                    "Code": -1,
                    "Message": "Failed to parse JSON"
                }, event);

                return;
            }

            if(event.target.status == 200)
            {
                if(this.SuccessCodes.includes(json.Code))
                {
                    this.EventDone();
                    this.EventSuccess(json, event);
                }
                else
                {
                    this.EventDone();
                    this.EventFailed(json, event);
                }
            }
            else
            {
                this.EventDone();
                this.EventFailed(json, event);
            }


        });

        hndl.addEventListener("error", event => {
            
            this.EventDone();
            this.EventFailed({
                "Code": 500,
                "Message": "The request failed, see console for details"
            }, event);

        });
        
        hndl.addEventListener("abort", event => {

            this.EventDone();
            this.EventFailed({
                "Code": 500,
                "Message": "The request was aborted"
            }, event);

        });

        hndl.upload.addEventListener("progress", event => {

            var percent = 0;

            if(event.lengthComputable)
            {
                percent = event.loaded / event.total * 100;
            }

            this.EventProgress(percent, event);

        });

        var sendData = this.Data;

        if(this.HasFormData && this.FormDataObj != null)
            sendData = this.FormDataObj;

        hndl.send(sendData);

    }

    static Get(url, useRoute = true)
    {
        return new LambdaHTTP(HTTPMethod.GET, url, useRoute);
    }

    static Post(url, useRoute = true)
    {
        return new LambdaHTTP(HTTPMethod.POST, url, useRoute);
    }

    static CSRFToken()
    {
        return $('meta[name="csrf-token"]').attr('content');
    }

    static GetBaseURL()
    {
        return $('meta[name="base-url"]').attr('content');
    }

    static GetHTMLErrors(response)
    {
        if(response.HasErrors == true)
        {

            var finalHtml = `
            <p><strong>${response.Message}</strong></p>
            <ul>\n`;

            for(let keyName of Object.keys(response.Errors))
            {
                var errorList = response.Errors[keyName];

                for(let errMsg of errorList)
                {
                    finalHtml = finalHtml + `<li>${errMsg}</li>\n`;
                }
            }

            finalHtml = finalHtml + `</ul>`;

            return finalHtml;

        }
        else
        {
            return response.Message;
        }
    }

}

window.HTTPMethod = HTTPMethod;
window.HTTPContent = HTTPContent;
window.LambdaHTTP = LambdaHTTP;